import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './DevelopmentSettings.scss';
import { useTranslation } from 'react-i18next';
import Switcher from 'common/components/Switcher/Switcher';
import FeaturesContext from 'common/contexts/features';
import { ClientFeatureEnum } from 'common/utils/features/client-features-config';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { checkIsStaging } from 'common/utils/test-urls';

type ClientFeatureControlPropsT = {
    feature: ClientFeatureEnum;
    children: (props: { checked: boolean; toggle: () => void }) => React.ReactElement;
};

const ClientFeatureControl: React.FC<ClientFeatureControlPropsT> = (props) => {
    const { feature, children } = props;

    const featuresContext = React.useContext(FeaturesContext);

    const value = !!featuresContext?.checkActiveClientFeature?.(feature);

    return children({
        checked: value,
        toggle: () => {
            featuresContext?.setClientFeature?.(feature, !value);
        },
    });
};

const cx = classNames.bind(styles);

type PropsT = {};

const labelI18nMap: Record<ClientFeatureEnum, string> = {
    [ClientFeatureEnum.errorDetails]: 'common:development.show-detail-errors',
    [ClientFeatureEnum.i18nLanguageSwitcher]: 'common:development.show-local-switcher',
    [ClientFeatureEnum.debugAlertForm]: 'common:development.show-debug-alert-form',
    [ClientFeatureEnum.debugNotificationsForm]: 'common:development.show-debug-notifications-form',
    [ClientFeatureEnum.brokerOrders]: 'common:development.show-broker-orders',
    [ClientFeatureEnum.partnerDrivers]: 'common:development.show-partner-drivers',
    [ClientFeatureEnum.partnerInvoices]: 'common:development.show-partner-invoices',
    [ClientFeatureEnum.partnerOrders]: 'common:development.show-partner-orders',
    [ClientFeatureEnum.assignmentExcludeCountries]: 'common:development.assignment-exclude-countries',
    [ClientFeatureEnum.routeEditing]: 'common:development.route-editing',
};

const hideFeatureSet = new Set<ClientFeatureEnum>(
    checkIsStaging
        ? [
              ClientFeatureEnum.debugAlertForm,
              ClientFeatureEnum.debugNotificationsForm,
              ClientFeatureEnum.brokerOrders,
              ClientFeatureEnum.partnerDrivers,
              ClientFeatureEnum.partnerInvoices,
              ClientFeatureEnum.partnerOrders,
              ClientFeatureEnum.routeEditing,
          ]
        : [],
);

const DevelopmentSettings: React.FC<PropsT> = (props) => {
    const { t } = useTranslation();

    const featuresContext = React.useContext(FeaturesContext);

    return (
        <div className={cx('inner')}>
            {(Object.values(ClientFeatureEnum) as ClientFeatureEnum[]).map((clientFeature) => {
                const isHidden = hideFeatureSet.has(clientFeature);
                if (isHidden) {
                    return null;
                }

                return (
                    <ClientFeatureControl key={clientFeature} feature={clientFeature}>
                        {(props) => (
                            <Switcher
                                checked={props.checked}
                                onToggle={props.toggle}
                                className={cx('control')}
                                label={t(labelI18nMap[clientFeature])}
                            />
                        )}
                    </ClientFeatureControl>
                );
            })}
            <div>
                <Button
                    theme={ButtonThemeEnum.secondary}
                    onClick={() => {
                        featuresContext?.clearClientFeatures?.();
                    }}
                >
                    {t('common:development.reset')}
                </Button>
            </div>
        </div>
    );
};

export default DevelopmentSettings;
