import React from 'react';
import classNames from 'classnames/bind';

import styles from './TrailerDetailsForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectTrailersDict, selectTrailersDictById } from 'common/store/trailers-dict/selectors';
import { AssetTypeEnum, StyleGuideColorsEnum, TrailerTypeEnum, UnitTypeEnum } from 'common/constants';
import { FieldsEnum, FormValuesT } from './constants';
import DictTrailerDropdown from 'common/components/dropdowns/DictTrailerDropdown/DictTrailerDropdown';
import TrailerTypeSwitcher from 'common/components/TrailerTypeSwitcher/TrailerTypeSwitcher';
import validateForm from './validate-form';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { uppercaseFormatter } from 'common/utils/form-formatters';
import FormikField from 'common/components/forms/FormikField/FormikField';
import CountryDropdown from 'common/components/dropdowns/CountryDropdown/CountryDropdown';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import Input from 'common/components/Input/Input';
import ActiveContractDropdown from 'common/components/dropdowns/ActiveContractDropdown/ActiveContractDropdown';
import NumberInput from 'common/components/NumberInput/NumberInput';
import { ApiTrailerDetailsT } from 'common/store/trailers/models';
import FieldValue from 'common/components/FieldValue/FieldValue';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import GPSTrackingStatusAlert from 'common/components/GPSTrackingStatusAlert/GPSTrackingStatusAlert';
import LastGPSPositionAlert from 'common/components/LastGPSPositionAlert/LastGPSPositionAlert';
import { GPStatusEnum } from 'common/store/gps-tracking-status/constants';
import { selectGPSStatus } from 'common/store/gps-tracking-status/selectors';
import noop from 'lodash/noop';
import TrailerIcon from 'common/icons/TrailerIcon';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import { selectCarrierContractById } from 'common/store/carrier-contracts/selectors';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { usePlateNumberUniqCheck } from 'common/components/AssetSidebarContent/hooks/usePlateNumberUniqCheck';
import ChangeRateWarning from '../ChangeRateWarning/ChangeRateWarning';
import useOnceShow from 'common/utils/hooks/useOnceShow';
import TrailerType from 'common/layouts/CommonEditableTrailerDetailsLayout/TrailerType/TrailerType';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import values from 'lodash/values';
import LinkedTruckAlert from 'common/layouts/CommonEditableTrailerDetailsLayout/LinkedTruckAlert/LinkedTruckAlert';
import { TooltipPositionEnum } from 'design-system/components/Tooltip/Tooltip';
import { ApiTrailerTypeT, CountryCodeT, TrailerStatusEnum } from 'common/utils/api/models';
import { AlertGroup } from 'common/layouts/AlertGroup/AlertGroup';
import ChangeContractWarning from '../ChangeContractWarning/ChangeContractWarning';
import TrailerActionHistory from '../../TrailerActionHistory/TrailerActionHistory';
import { checkIsApiCarrierTrailerDetails } from 'common/store/trailers/type-guards';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import { selectCountriesByCode } from 'common/store/countries-dict/selectors';

const cx = classNames.bind(styles);

type PropsT = {
    partnerId: PartnerIdT;
    onSubmit: (values: FormValuesT) => void;
    isNewTrailer: boolean;
    isLoading: boolean;
    trailerDetails: ApiTrailerDetailsT | null;
    onCancel: () => void;
    setNeedCloseConfirmation?: (needCloseConfirmation: boolean) => void;
    onOpenTruckDetails: (truckId: TruckIdT) => void;
    onOpenTrailerDetails: (trailerId: TrailerIdT) => void;
    onOpenUserDetails: (userId: UserIdT, isBrokerUser?: boolean) => void;
};

const DEFAULT_TRAILERS_DICT = {
    [TrailerTypeEnum.tilt]: [],
    [TrailerTypeEnum.box]: [],
};

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.trailerType]: TrailerTypeEnum.tilt,
    [FieldsEnum.trailerDictId]: null,
    [FieldsEnum.countryCode]: null,
    [FieldsEnum.plateNumber]: '',
    [FieldsEnum.vin]: '',
    [FieldsEnum.contract]: null,
    [FieldsEnum.ratePerKm]: '',
};

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

const TrailerDetailsForm: React.FC<PropsT> = (props) => {
    const {
        partnerId,
        onSubmit,
        isNewTrailer,
        trailerDetails,
        isLoading,
        onCancel,
        setNeedCloseConfirmation,
        onOpenTrailerDetails,
        onOpenTruckDetails,
        onOpenUserDetails,
    } = props;

    const { t } = useTranslation();

    const trailersDictById = useSelector(selectTrailersDictById);
    const trailersDict = useSelector(selectTrailersDict);

    const countryByCode = useSelector(selectCountriesByCode);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    React.useEffect(() => {
        return () => {
            if (setNeedCloseConfirmation) {
                setNeedCloseConfirmation(false);
            }
        };
    }, []);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values, { isCreation: isNewTrailer });
    }, [t, isNewTrailer]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        let values = {
            ...INITIAL_VALUES,
        };

        if (trailerDetails && trailersDictById) {
            values = {
                ...values,
                [FieldsEnum.trailerType]: trailerDetails.dictTrailerInfo?.trailerType || values[FieldsEnum.trailerType],
                [FieldsEnum.trailerDictId]: String(trailerDetails.dictTrailerInfo?.id),
                [FieldsEnum.countryCode]: trailerDetails.countryCode || null,
                [FieldsEnum.vin]: trailerDetails.vin || '',
                [FieldsEnum.plateNumber]: trailerDetails.plateNumber || '',
                [FieldsEnum.contract]: trailerDetails.contractId || null,
                [FieldsEnum.ratePerKm]: String(trailerDetails.ratePerKm),
            };
        }

        const errors = validateForm(t, values, { isCreation: isNewTrailer });

        return [values, errors];
    }, [trailerDetails, trailersDictById, isNewTrailer]);

    const formik = useFormik<FormValuesT>({
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            onSubmit(values);

            formikHelpers.setTouched({});
        },
    });

    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    React.useEffect(() => {
        if (hasAnyFieldValueChanges && setNeedCloseConfirmation) {
            setNeedCloseConfirmation(hasAnyFieldValueChanges);
        }
    }, [setNeedCloseConfirmation, hasAnyFieldValueChanges]);

    const gpsStatus = useSelector(selectGPSStatus);

    const selectedTrailerType = formik.values[FieldsEnum.trailerType];

    const availableTrailersTypes = React.useMemo(() => {
        return Object.keys(trailersDict || DEFAULT_TRAILERS_DICT) as Array<TrailerTypeEnum>;
    }, [trailersDict]);

    const handleChangeTrailerTypeValue = React.useCallback(
        (field: string, value: ApiTrailerTypeT): void => {
            formik.setFieldValue(FieldsEnum.trailerType, value);
            formik.setFieldValue(FieldsEnum.trailerDictId, null);
        },
        [dispatch, formik],
    );

    const contractById = useSelector(selectCarrierContractById(partnerId));
    const selectedContractId = formik.values[FieldsEnum.contract];
    const contract = contractById[selectedContractId as string] || null;

    const contractRatePerKm =
        formik.values[FieldsEnum.trailerType] === TrailerTypeEnum.box ? contract?.boxRateKm : contract?.tiltRateKm;

    const isAvailableSelectContract = gpsStatus === GPStatusEnum.enabled || !gpsStatus;
    const isAvailableChangeRate = formik.values[FieldsEnum.contract] === null;

    const trailerDictModel = trailerDetails?.dictTrailerInfo || null;

    const trailerTypeLabel = trailerDictModel
        ? t(`common:trailers-page.add-trailer.fields.trailer.${trailerDictModel?.trailerType}.label`)
        : '';

    const plateNumber = formik.values[FieldsEnum.plateNumber];
    const trailerPlateNumberUniqCheck = usePlateNumberUniqCheck({
        plateNumber,
        companyId: partnerId,
        assetType: AssetTypeEnum.trailer,
    });

    let plateNumberAsyncError: React.ReactNode = null;

    const duplicateTrailerId = trailerPlateNumberUniqCheck.duplicateId;
    if (duplicateTrailerId) {
        plateNumberAsyncError = (
            <Link
                onClick={() => {
                    onOpenTrailerDetails(duplicateTrailerId);
                }}
                theme={LinkThemeEnum.boldTomatoRed}
            >
                {t('common:trailers-page.add-trailer.errors.not-uniq-plate-number', { plateNumber })}
            </Link>
        );
    }

    const isAvailableShowRateWarning = isAvailableChangeRate;
    const isShowRateWarning =
        !isNewTrailer && initialValues[FieldsEnum.ratePerKm] !== formik.values[FieldsEnum.ratePerKm];
    const rateWarning = useOnceShow(isShowRateWarning);

    const isAvailableShowContractWarning = !!formik.values[FieldsEnum.contract];
    const isShowContractWarning =
        !isNewTrailer && initialValues[FieldsEnum.contract] !== formik.values[FieldsEnum.contract];
    const contractWarning = useOnceShow(isShowContractWarning);

    const hasAsyncErrors = !!plateNumberAsyncError;

    const isAllowShowChanges = !isNewTrailer;

    const contractTooltipNode = (
        <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
            {t('common:trailers-page.add-trailer.fields.contract.tooltip')}
        </TooltipContent>
    );

    return (
        <form onSubmit={formik.handleSubmit}>
            <AlertGroup className={cx('alerts')}>
                {trailerDetails?.linkedTruckId && (
                    <LinkedTruckAlert
                        plateNumber={trailerDetails?.linkedTruckPlateNumber}
                        truckId={trailerDetails.linkedTruckId}
                        onOpenTruckDetails={onOpenTruckDetails}
                    />
                )}
            </AlertGroup>
            <div className={cx('fields')}>
                {isNewTrailer ? (
                    <>
                        <div className={cx('field', 'field--trailer-type')}>
                            <TrailerTypeSwitcher
                                value={formik.values[FieldsEnum.trailerType]}
                                name={FieldsEnum.trailerType}
                                availableTypes={availableTrailersTypes}
                                setFieldValue={handleChangeTrailerTypeValue}
                            />
                        </div>
                        <FormikField
                            name={FieldsEnum.trailerDictId}
                            error={formik.errors[FieldsEnum.trailerDictId]}
                            meta={formik.getFieldMeta(FieldsEnum.trailerDictId)}
                            label={t(`common:trailers-page.add-trailer.fields.trailer.${selectedTrailerType}.label`)}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <DictTrailerDropdown
                                    placeholder={t(
                                        `common:trailers-page.add-trailer.fields.trailer.${selectedTrailerType}.placeholder`,
                                    )}
                                    selectedTrailerType={formik.values[FieldsEnum.trailerType]}
                                    value={formik.values[FieldsEnum.trailerDictId]}
                                    onChange={props.onChange}
                                    hasWarning={props.hasWarning}
                                    hasError={props.hasError}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasChanges={isAllowShowChanges && props.hasChanges}
                                />
                            )}
                        </FormikField>
                        {trailerDetails?.status !== TrailerStatusEnum.archived && (
                            <GPSTrackingStatusAlert
                                companyId={partnerId}
                                assetType={AssetTypeEnum.trailer}
                                plateNumber={formik.values[FieldsEnum.plateNumber]}
                                className={cx('gps-tracking-status-alert')}
                            />
                        )}
                        <FieldGroup>
                            <FormikField
                                className={cx('field', 'field--plate-number')}
                                name={FieldsEnum.plateNumber}
                                error={formik.errors[FieldsEnum.plateNumber]}
                                meta={formik.getFieldMeta(FieldsEnum.plateNumber)}
                                asyncError={plateNumberAsyncError}
                                label={t('common:trailers-page.add-trailer.fields.plate-number.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.plateNumber}
                                        value={formik.values[FieldsEnum.plateNumber]}
                                        placeholder={t(
                                            'common:trailers-page.add-trailer.fields.plate-number.placeholder',
                                        )}
                                        formatter={uppercaseFormatter}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        isLoading={trailerPlateNumberUniqCheck.requestStatus.loading}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field', 'field--vin')}
                                name={FieldsEnum.vin}
                                error={formik.errors[FieldsEnum.vin]}
                                meta={formik.getFieldMeta(FieldsEnum.vin)}
                                label={t('common:trailers-page.add-trailer.fields.trailer-vin.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.vin}
                                        value={formik.values[FieldsEnum.vin]}
                                        placeholder={t(
                                            'common:trailers-page.add-trailer.fields.trailer-vin.placeholder',
                                        )}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={isAllowShowChanges && props.hasChanges}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                    </>
                ) : (
                    <>
                        {trailerDetails?.status !== TrailerStatusEnum.archived && (
                            <LastGPSPositionAlert
                                companyId={partnerId}
                                assetType={AssetTypeEnum.trailer}
                                plateNumber={trailerDetails?.plateNumber || ''}
                                className={cx('last-gps-position-alert')}
                                isDedicated={!!trailerDetails?.contractId}
                            />
                        )}
                        <TrailerType className={cx('trailer-type')} trailerType={trailerDictModel?.trailerType} />
                        <FieldValue
                            label={trailerTypeLabel}
                            icon={
                                <TrailerIcon
                                    size={20}
                                    fillColor={StyleGuideColorsEnum.brandAccent}
                                    strokeColor={StyleGuideColorsEnum.brandDark}
                                />
                            }
                            value={
                                trailerDictModel
                                    ? t('common:trailer-subtype-template', {
                                          length: trailerDictModel.length,
                                          model: trailerDictModel.model,
                                          eur1Pallets: trailerDictModel.eur1Pallets,
                                          eur2Pallets: trailerDictModel.eur2Pallets,
                                      })
                                    : ''
                            }
                        />
                        <FieldGroup>
                            <FieldValue
                                className={cx('field', 'field--plate-number')}
                                label={t('common:trailers-page.add-trailer.fields.plate-number.label')}
                                value={trailerDetails?.plateNumber || ''}
                            />
                            <FieldValue
                                className={cx('field', 'field--vin')}
                                label={t('common:trailers-page.add-trailer.fields.trailer-vin.label')}
                                value={trailerDetails?.vin}
                            />
                        </FieldGroup>
                    </>
                )}
                {isNewTrailer ? (
                    <FormikField
                        className={cx('field', 'field--country')}
                        name={FieldsEnum.countryCode}
                        error={formik.errors[FieldsEnum.countryCode]}
                        label={t('common:trailers-page.add-trailer.fields.country.label')}
                        meta={formik.getFieldMeta(FieldsEnum.countryCode)}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <CountryDropdown
                                value={formik.values[FieldsEnum.countryCode]}
                                onChange={props.onChange}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasChanges={isAllowShowChanges && props.hasChanges}
                            />
                        )}
                    </FormikField>
                ) : (
                    <FieldValue
                        className={cx('field', 'field--country')}
                        icon={<FlagIcon countryCode={trailerDetails?.countryCode} />}
                        label={t('common:trucks-page.add-truck.fields.country.label')}
                        value={countryByCode?.[trailerDetails?.countryCode as CountryCodeT]?.userLangDisplayName}
                    />
                )}
                <FieldGroup>
                    {isAvailableSelectContract ? (
                        <FormikField
                            className={cx('field', 'field--contract')}
                            name={FieldsEnum.contract}
                            error={formik.errors[FieldsEnum.contract]}
                            meta={formik.getFieldMeta(FieldsEnum.contract)}
                            label={t('common:trailers-page.add-trailer.fields.contract.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                            tooltipPosition={TooltipPositionEnum.centerRight}
                            tooltipNode={contractTooltipNode}
                        >
                            {(props) => (
                                <ActiveContractDropdown
                                    hasNoneOption
                                    value={formik.values[FieldsEnum.contract]}
                                    isDisabled={false}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    companyId={partnerId}
                                    hasChanges={isAllowShowChanges && props.hasChanges}
                                />
                            )}
                        </FormikField>
                    ) : (
                        <FieldValue
                            withInputLayout
                            className={cx('field', 'field--contract')}
                            label={t('common:trailers-page.add-trailer.fields.contract.label')}
                            tooltipPosition={TooltipPositionEnum.centerRight}
                            tooltipNode={contractTooltipNode}
                            value={t('common:dedicated.none')}
                        />
                    )}
                    <FormikField
                        className={cx('field', 'field--rate')}
                        name={FieldsEnum.ratePerKm}
                        error={formik.errors[FieldsEnum.ratePerKm]}
                        meta={formik.getFieldMeta(FieldsEnum.ratePerKm)}
                        label={t('common:trailers-page.add-trailer.fields.rate.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <NumberInput
                                name={FieldsEnum.ratePerKm}
                                unitType={UnitTypeEnum.euroAbbreviation}
                                placeholder={t('common:trailers-page.add-trailer.fields.rate.placeholder')}
                                value={
                                    isAvailableChangeRate
                                        ? formik.values[FieldsEnum.ratePerKm]
                                        : String(contractRatePerKm)
                                }
                                onChange={isAvailableChangeRate ? props.onChange : noop}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasChanges={isAllowShowChanges && props.hasChanges}
                                step={1}
                                isDisabled={!isAvailableChangeRate}
                            />
                        )}
                    </FormikField>
                </FieldGroup>
            </div>
            <TrailerActionHistory
                onOpenUserDetails={onOpenUserDetails}
                createdDate={trailerDetails?.createdDate}
                createdByBroker={checkIsApiCarrierTrailerDetails(trailerDetails) && trailerDetails?.addedByBroker}
                createdByName={trailerDetails?.createdBy?.fullName}
                createdById={trailerDetails?.createdBy?.id}
                modifiedDate={trailerDetails?.lastModifiedDate}
                modifiedByBroker={
                    checkIsApiCarrierTrailerDetails(trailerDetails) && trailerDetails?.lastModifiedByBroker
                }
                modifiedByName={trailerDetails?.lastModifiedBy?.fullName}
                modifiedById={trailerDetails?.lastModifiedBy?.id}
            />
            <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            <FooterSideBarLayout
                hasPaddings
                topNode={
                    <>
                        {isAvailableShowRateWarning && rateWarning.isShow ? (
                            <ChangeRateWarning className={cx('footer-warning')} onClose={rateWarning.close} />
                        ) : null}
                        {isAvailableShowContractWarning && contractWarning.isShow ? (
                            <ChangeContractWarning className={cx('footer-warning')} onClose={contractWarning.close} />
                        ) : null}
                    </>
                }
            >
                {isNewTrailer && (
                    <Button
                        theme={ButtonThemeEnum.primary}
                        isLoading={isLoading}
                        isDisabled={hasAsyncErrors}
                        className={cx('button')}
                        type="submit"
                    >
                        {t('common:trailers-page.add-trailer.submit')}
                    </Button>
                )}
                {!isNewTrailer && (
                    <div className={cx('actions')}>
                        <Button
                            theme={ButtonThemeEnum.secondary}
                            className={cx('actions__action', 'actions__action--cancel')}
                            onClick={() => {
                                formik.resetForm();
                                onCancel();
                            }}
                        >
                            {t('common:trailers-page.actions.discard-changes')}
                        </Button>
                        <Button
                            theme={ButtonThemeEnum.primary}
                            isLoading={isLoading}
                            isDisabled={hasAsyncErrors}
                            className={cx('actions__action', 'actions__action--delete')}
                            type="submit"
                        >
                            {t('common:trailers-page.actions.save')}
                        </Button>
                    </div>
                )}
            </FooterSideBarLayout>
        </form>
    );
};
export default TrailerDetailsForm;
