import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PriceDetails.scss';
import cs from 'classnames';

import Money from 'common/components/Money/Money';

import isNumber from 'lodash/isNumber';
import { CurrencyEnum, StyleGuideColorsEnum } from 'common/constants';
import ExpandIcon from 'common/icons/ExpandIcon';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipIconTrigger from 'design-system/components/Tooltip/TooltipIconTrigger/TooltipIconTrigger';
import ColoredDiff from 'common/components/ColoredDiff/ColoredDiff';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { useTranslation } from 'react-i18next';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';

const cx = classNames.bind(styles);

export type PriceDetailT = {
    title?: React.ReactNode;
    placeholder?: React.ReactNode;
    price?: number | null;
    priceDiff?: number | null;
    notActualPrice?: number | null;
    customPriceDiffNegativeColor?: StyleGuideColorsEnum;
    isInvertedPriceDiff?: boolean | null;
    tooltipNode?: React.ReactNode;
    pillLabelNode?: React.ReactNode;
    pillLabelTheme?: PillLabelThemeEnum;
    shouldRenderIcon?: boolean;
    iconNode?: React.ReactNode;
    leftNode?: React.ReactNode;
    rightNode?: React.ReactNode;
    list?: Array<PriceDetailT | null>;
    initialOpen?: boolean;
    shouldRenderExpandTrigger?: boolean;
};

type PriceDetailPropsT = PriceDetailT & {
    nestLevel: number;
};

const SHOW_TOOLTIP_DELAY = 300;

const STEP_MARGIN_LEFT_PX = 30; // px

const PriceDetail: React.FC<PriceDetailPropsT> = (props) => {
    const {
        title,
        placeholder,
        price,
        priceDiff,
        customPriceDiffNegativeColor,
        notActualPrice,
        pillLabelNode,
        pillLabelTheme,
        isInvertedPriceDiff,
        shouldRenderIcon,
        iconNode,
        leftNode,
        rightNode,
        list,
        tooltipNode,
        nestLevel,
        initialOpen,
        shouldRenderExpandTrigger,
    } = props;

    const { t } = useTranslation();

    const isShowRow = !!title;

    const [isOpen, setOpen] = React.useState<boolean>(!!initialOpen || !isShowRow);

    const isClickable = !!list?.length;

    const toggle = (event: React.MouseEvent): void => {
        setOpen(!isOpen);
        event.stopPropagation();
    };

    const moneyNode = isNumber(price) ? <Money amount={price} currency={CurrencyEnum.EUR} /> : null;

    return (
        <>
            {isShowRow && (
                <div
                    className={cx('row', {
                        'row--isClickable': isClickable,
                    })}
                    onClick={toggle}
                    style={{
                        marginLeft: `${nestLevel * STEP_MARGIN_LEFT_PX}px`,
                    }}
                >
                    <div className={cx('column', 'column--content')}>
                        <div className={cx('content')}>
                            {shouldRenderIcon && (
                                <div className={cx('content-column', 'content-column--icon')}>{iconNode}</div>
                            )}
                            {!!leftNode && (
                                <div className={cx('content-column', 'content-column--left-node')}>{leftNode}</div>
                            )}
                            <div className={cx('content-column', 'content-column--title')}>
                                {title}
                                {!!tooltipNode && (
                                    <Tooltip
                                        className={cx('tooltip')}
                                        position={TooltipPositionEnum.topCenter}
                                        theme={TooltipThemeEnum.black}
                                        tooltipNode={tooltipNode}
                                        delay={SHOW_TOOLTIP_DELAY}
                                    >
                                        {(isShow) => <TooltipIconTrigger isShow={isShow} />}
                                    </Tooltip>
                                )}
                                {!!pillLabelNode && (
                                    <PillLabel
                                        className={cx('pill')}
                                        isSymmetrical
                                        theme={pillLabelTheme || PillLabelThemeEnum.charcoal}
                                    >
                                        {pillLabelNode}
                                    </PillLabel>
                                )}
                            </div>
                            {moneyNode ? (
                                <div className={cx('content-column', 'content-column--price')}>
                                    {isNumber(notActualPrice) && isNumber(price) && notActualPrice !== price ? (
                                        <Money
                                            className={cx('not-actual-price')}
                                            amount={notActualPrice}
                                            currency={CurrencyEnum.EUR}
                                        />
                                    ) : null}
                                    {isNumber(priceDiff) ? (
                                        <ColoredDiff
                                            diff={-1 * priceDiff}
                                            isInverted={!!isInvertedPriceDiff}
                                            customNegativeColor={customPriceDiffNegativeColor}
                                        >
                                            {moneyNode}
                                        </ColoredDiff>
                                    ) : (
                                        moneyNode
                                    )}
                                </div>
                            ) : (
                                <div className={cx('content-column', 'content-column--placeholder')}>
                                    {placeholder || ''}
                                </div>
                            )}
                            {!!rightNode && (
                                <div className={cx('content-column', 'content-column--right-node')}>{rightNode}</div>
                            )}
                        </div>
                    </div>
                    {shouldRenderExpandTrigger && (
                        <div className={cx('column', 'column--trigger')}>
                            {isClickable && (
                                <Tooltip
                                    position={TooltipPositionEnum.topCenter}
                                    theme={TooltipThemeEnum.black}
                                    tooltipNode={
                                        <TooltipContent isNoWrap theme={TooltipContentThemeEnum.black}>
                                            {t(isOpen ? 'common:tooltips.collapse' : 'common:tooltips.expand')}
                                        </TooltipContent>
                                    }
                                >
                                    {() => (
                                        <TransparentTrigger
                                            spaces="xs"
                                            onClick={() => {
                                                // nothing, use common handler
                                            }}
                                            leftIcon={
                                                <ExpandIcon
                                                    fillColor={StyleGuideColorsEnum.brandAccent}
                                                    isInvert={isOpen}
                                                />
                                            }
                                            reflectionTheme={ReflectionThemeEnum.light}
                                        />
                                    )}
                                </Tooltip>
                            )}
                        </div>
                    )}
                </div>
            )}
            {!!list?.length && isOpen && (
                <PriceDetails
                    list={list}
                    hasVerticalPaddings={isShowRow}
                    nestLevel={nestLevel + 1}
                    className={cx('nested-list')}
                />
            )}
        </>
    );
};

export type PriceDetailsPropsT = {
    className?: string;
    list: Array<PriceDetailT | null>;
    shouldAlwaysRenderExpandTrigger?: boolean;
    nestLevel?: number; // inner field
    hasVerticalPaddings?: boolean; // inner field
};

const PriceDetails: React.FC<PriceDetailsPropsT> = React.memo((props) => {
    const { list, nestLevel = 0, shouldAlwaysRenderExpandTrigger, hasVerticalPaddings, className } = props;

    const hasIcons = list.some((item) => !!item?.iconNode);

    const shouldRenderExpandTrigger =
        shouldAlwaysRenderExpandTrigger || !(nestLevel === 0 && list.every((item) => !item?.list?.length));

    return (
        <div
            className={cs(
                cx('list', {
                    'list--hasVerticalPaddings': hasVerticalPaddings,
                }),
                className,
            )}
        >
            {list.map((item, itemIndex) => {
                if (!item) {
                    return null;
                }

                return (
                    <PriceDetail
                        key={itemIndex}
                        shouldRenderIcon={hasIcons}
                        iconNode={item.iconNode}
                        title={item.title}
                        price={item.price}
                        priceDiff={item.priceDiff}
                        isInvertedPriceDiff={item.isInvertedPriceDiff}
                        notActualPrice={item.notActualPrice}
                        customPriceDiffNegativeColor={item.customPriceDiffNegativeColor}
                        placeholder={item.placeholder}
                        leftNode={item.leftNode}
                        rightNode={item.rightNode}
                        tooltipNode={item.tooltipNode}
                        pillLabelNode={item.pillLabelNode}
                        pillLabelTheme={item.pillLabelTheme}
                        list={item.list}
                        initialOpen={item.initialOpen}
                        nestLevel={nestLevel}
                        shouldRenderExpandTrigger={shouldRenderExpandTrigger}
                    />
                );
            })}
        </div>
    );
});

export default PriceDetails;
